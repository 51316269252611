.home {
  height: 100vh;
  background: rgb(218, 237, 255);
  width: 100%;
}

.home-header {
  background: rgba(185, 221, 255, 1);
  z-index: 2;
}

.log-user-img {
  width: 40px;
  height: 40px;
  border-radius: 25px;
    object-fit: cover;
}
.user-name {
  font-family: "poppins";
  font-weight: 500;
}
.load-section {
  /* margin-left: 300px; */
  height:  calc(100vh - 82px);
  overflow-y: scroll;
  width: 80%;
}
.side-bar {
  /* position: absolute; */
  height:  calc(100vh - 75px);
 
  width: 20%;
  background: rgba(0, 47, 90, 1);
  z-index: 1;
}
.side-bar-btn {
    border: none;
  width: 76%;
  background: rgb(56, 149, 234, 0.3);
  border-radius: 12px;
  line-height: 16px;
    font-size: 16px;
    font-family: "Manrope";
    color:#ffffff;
    filter: brightness(80%);
}

.side-img{
    width: 20px;
    height: 20px;
    
   
}

/* .btn-txt {
    line-height: 16px;
    font-size: 16px;
    font-family: "Manrope";
    color:#ffffff
} */

/* .side-bar-btn:hover{
  background: #3895EA;
  filter: brightness(120%);
  
  
} */

.side-bar-btn-shut{
  border: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .side-bar-btn {
    width: 80%;
  }
}


/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .side-bar-btn {
      width: auto;
    }

    .side-bar {
      width: 15%;
    }

    .load-section {
      width: 85%;
    }
}

