.admin-add-footer{
    background: #ffffff;
    border-radius: 0px 0px 15px 15px;
}
.add-admin-img{
    width: 90px;
    height: 90px;
    border-radius: 45px;
}

.add-admin-profile{
    font-family: 'poppins';
    font-weight: 400;
    font-size: 14px;
}
.add-admin-img-btn{
    border: none;
    font-family: 'poppins';
    font-size: 14px;
    background: rgba(0, 47, 90, 1);
    color: rgba(255, 255, 255, 1);
    border-radius:10px ;
    cursor: pointer;
}

.add-admin-btn-wrap {
    justify-content: start;
}

.add-new-admin-btn{
    border: none;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 12px;
    color:rgba(255, 255, 255, 1);
    background: rgba(22, 183, 19, 1);
    border-radius:7px ;
}

@media screen and (max-width: 768px) {
    .add-admin-btn-wrap {
        justify-content: center;
    }
}