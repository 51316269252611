.contact_us__container {
  height: 100%;
  width: 100vw;
  background-color: rgb(218, 237, 255) !important;
  padding: 50px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact_us__container__map_container {
  width: 100%;
  height: 300px;
}

.contact_us__container__contact_details_container {
  padding: 15px;
  width: 100%;
  background-color: white;
}

.contact_us__container__contact_details_container__heading {
  font-weight: bold;
  font-size: 2rem;
  align-self: center;
  padding: 20px 20px 20px 0;
  color: rgb(3, 1, 76);
}

.contact_us__container__contact_details_container__subheading {
  font-weight: bold;
  font-size: 0.9rem;
  align-self: center;
  padding: 2px;
  color: rgb(0, 87, 167);
}

.contact_us__container__contact_details_container__subheading > span {
  font-weight: bold;
  font-size: 0.9rem;
  align-self: center;
  padding: 2px;
  color: rgb(128, 129, 145);
}

.contact_us__container__contact_details_container__text {
  font-weight: bold;
  font-size: 0.9rem;
  align-self: center;
  padding: 2px;
  color: rgb(128, 129, 145);
}

.contact_us__container__contact_form_container {
  margin-top: 20px;
  padding: 40px 15px 25px 40px;
  width: 100%;
  background-color: rgb(56, 149, 234);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.custom-input {
  width: 90%;
  background-color: rgb(198, 228, 255);
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-bottom: 10px;
  color: black;
}

.custom-textarea {
  width: 90%;
  background-color: rgb(198, 228, 255);
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-bottom: 10px;
  resize: vertical; /* Allow vertical resizing of textarea */
  color: black;
}

.contact_us__container__contact_form_container-btn {
  margin-top: 20px;
  padding: 8px 25px 8px 25px;
  background: #002f5a;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 400px;
  color: white;
  cursor: pointer;
}
