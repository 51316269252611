.filters-container {
  background: rgba(149, 149, 149, 0.2);
}

.user-reg-search {
  width: 300px;
  height: 48px;
  border: none;
  background: #f7f7f7;
  border-radius: 10px;
  font-family: "poppins";
  font-weight: 400;
  font-size: 16px;
}

.user-name-search {
  width: 280px;
  height: 48px;
  border: none;
  background: #f7f7f7;
  border-radius: 10px;
  font-family: "poppins";
  font-weight: 400;
  font-size: 16px;
}

.user-class-search {
  width: 300px;
  height: 48px;
  border: none;
  background: #f7f7f7;
  border-radius: 10px;
  font-family: "poppins";
  font-weight: 400;
  font-size: 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #f7f7f7, #ffffff);
  background-position: calc(100% - 20px) calc(1em + 5px),
    calc(100% - 15px) calc(1em + 5px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.user-reg-search:focus {
  outline: none;
}
.user-name-search:focus {
  outline: none;
}
.user-class-search:focus {
  outline: none;
}

.user-search-btn {
  background: rgba(0, 47, 90, 1);
  border-radius: 10px;
}
.user-search-btn-txt {
  font-family: "poppins";
  font-weight: 500;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 1340px) {
  .user-reg-search {
    width: 250px;
  }
  .user-name-search {
    width: 250px;
  }
  .user-class-search {
    width: 250px;
  }
}
