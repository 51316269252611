.user-search-input{
    border: none;
}

.user-search-input:focus{
    outline: none;
}

.search-container{
    background-color: rgba(171, 214, 255, 1);
    width: 255px;
    border-radius: 15px;
    cursor: pointer;
}

.search-img{
    width: 20px;
}

.user-search-input{
    background-color: rgba(171, 214, 255, 1);
    font-family: "Manrope";
    font-size: 16px;
    cursor: pointer;
}

.user-pendinglist-btn{
    border: none;
    background: rgba(255, 186, 33, 1);
    color: rgba(136, 98, 15, 1);
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 17px;
    border-radius: 15px;
}

.add-user-btn {
    border: none;
    background: rgba(22, 183, 19, 1);
    color: white;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 17px;
    border-radius: 15px;
}

@media screen and (max-width: 768px) {
    .user-pendinglist-btn {
        font-size: 14px;
    }

    .add-user-btn {
        font-size: 14px;
    }

}


