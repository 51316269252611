.privacy_policy__container {
  height: 100%;
  width: 98vw;
  background-color: rgb(218, 237, 255);
  padding: 50px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.privacy_policy__container__heading {
  font-weight: bold;
  font-size: 2rem;
  align-self: center;
  padding: 20px;
  color: rgb(3, 1, 76);
}

.privacy_policy__container__subheading {
  padding-top: 20px;
  font-weight: bold;
  font-size: 1.5rem;
  color: rgb(3, 1, 76);
}

.privacy_policy__container__subheading2 {
  padding-top: 20px;
  font-weight: bold;
  font-size: 1rem;
  color: rgb(3, 1, 76);
}

.privacy_policy__container__text {
  padding-top: 12px;
  font-size: 0.85rem;
  color: rgb(63, 62, 64);
}

.privacy_policy__container__ul {
  /* margin: 0; */
  font-size: 0.85rem;
  color: rgb(63, 62, 64);
}

.privacy_policy__container__contact_address {
  padding-top: 5px;
  color: rgb(63, 62, 64);
  font-size: 0.85rem;
}
